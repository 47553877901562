.main-conatiner {
    border: 1px solid #334050;
    border-radius: 39px;
    max-width: 776px;
    width: 100%;
    margin: auto;
}

.upper-table-container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    border-spacing: 0 50px;
}

.heading-text {
    font-family: Montserrat, sans-serif;
    font-size: 10px;
    color: #707070;
    font-weight: 600;
}

.dynamic-text {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    color: #252422;
    margin-top: 10px;
    font-weight: bold;
}

.email.text {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    color: #252422;
    margin-top: 10px;
    margin-bottom: 60px;
    font-weight: bold;
}

.payment-text {
    font-family: Montserrat, sans-serif;
    font-size: 27px;
    color: #252422;
    margin-top: 10px;
    font-weight: bold;
}

.scan-text {
    font-family: Montserrat, sans-serif;
    font-size: 10px;
    margin-bottom: 20px;
    color: #707070;
}

.lower-table {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.lower-table-header-container {
    background-color: #334050;
    width: inherit;
    border: 1px solid #dedddd;
    border-radius: 16px;
    padding: 10px;
}

.description-text {
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    width: 32%;
    display: inline-block;
    text-align: left;
}

.amount-text {
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    width: 32%;
    display: inline-block;
    text-align: center;
}

.total-text {
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    width: 32%;
    display: inline-block;
    text-align: right;
}

.description-dynamic-text {
    color: black;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    width: 32%;
    display: inline-block;
    text-align: left;
}

.amount-dynamic-text {
    color: black;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    width: 32%;
    display: inline-block;
    text-align: center;
}

.total-dynamic-text {
    color: black;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    width: 33%;
    display: inline-block;
    text-align: right;
}

.button-container {
    background-color: #334050;
    border: 1px solid #dedddd;
    border-radius: 16px;
    width: 20%;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 30px;
}

.button-text {
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    display: inline-block;
    text-align: center;
    margin-right: 30px;
}

.button-dynamic-text {
    color: white;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    display: inline-block;
    text-align: center;
}
